import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Text, useBreakpoint, Grid } from 'basis';
import { useTheme as emotionUseTheme } from '@emotion/react';
import { LayoutPage, Form, Errors } from 'components';
import { useStoreValue, APOLLO_NETWORK_ERROR, APOLLO_UNKNOWN_ERROR } from 'store';
import { submitHandler, getDataLayerElements } from 'utils';
import { useEventTracking } from 'react-event-tracker';
import {
  APPLY_BTN_LABEL,
  PRODUCT_CODE_AFS,
  PRODUCT_CODE_AU_GEM,
  PRODUCT_CODE_GO,
  PRODUCT_CODE_LOW_RATE,
  PRODUCT_CODE_TWENTY_EIGHT_DEGREES,
  STEP_CONTACT_DETAILS,
} from '_config';
import GemBanner from 'images/banner/gem-banner.png';
import GemBannerMobile from 'images/banner/gem-banner-mobile.png';
import GoBanner from 'images/banner/go-banner.png';
import AfsBanner from 'images/banner/afs-banner.png';
import AfsCardImg from 'images/afs-card.png';
import Au28DBanner from 'images/banner/28d-banner.png';
import Au28DBannerMobile from 'images/banner/28d-banner-mobile.png';
import LowRateBanner from 'images/banner/low-rate-banner.png';
import LowRateBannerMobile from 'images/banner/low-rate-mobile-banner.png';
import {
  useInitApplication,
  useErrorTrackingAndUpdateStore,
  useFormInitialValues,
  useDataLayerAndAnalyticsLinks,
  useValidateOffers,
} from 'hooks';
import { byCard } from 'utils/byConfig';
import { LandingSubHeader } from 'components/merchant/LandingSubHeader';
import { isFeatureOn } from 'featureToggles';
import { OfferTile } from 'components/offer-tile/OfferTile';
import { Banner, BannerImage, ContentWrapper } from './LandingPageAu.styles';
import { FeaturesAndBenefitsModal } from './FeaturesAndBenefitsModal';
import { HeaderBannerTextArea } from './HeaderBannerTextArea';
import { useAdblockDetector } from '../../hooks/useAdblockDetector';
import { ApplicationOverview } from './ApplicationOverview';
import { ExplainAndStart } from './ExplainAndStart';

const FORM_ID = 'overview';

const formTealiumLocation = 'landing';
const pageTealiumLocation = 'what-to-expect';

export function LandingPageAU() {
  const theme = emotionUseTheme();
  const breakpoint = useBreakpoint();
  const history = useHistory();
  const [featureAndBenefitsModalOpen, setFeatureAndBenefitsModalOpen] = useState(false);
  const { trackEvent, getQueryString } = useEventTracking();
  const { handleErrorCallback } = useErrorTrackingAndUpdateStore();
  const [storeState, updateStore] = useStoreValue();
  const initialValues = useFormInitialValues(FORM_ID);
  const { trackEventSender } = useDataLayerAndAnalyticsLinks(storeState);
  const [isAdblockCheckComplete, isAdblockerDetected] = useAdblockDetector(
    `//tags.tiqcdn.com/utag/latitudefs/apply-funnel/${process.env.REACT_APP_TEALIUM_ENV}/utag.sync.js`,
  );

  useEffect(() => {
    if (isFeatureOn('balanceTransfer') && storeState?.applyAndBuy?.promoCode) {
      validateOffer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmitSuccess = data => {
    const updates = {
      applicationRef: data.applicationRef,
      applicationId: data.applicationId,
      isResuming: false,
    };

    updateStore({
      activeStep: STEP_CONTACT_DETAILS,
      ...updates,
    });

    trackEventSender(
      {
        category: 'application',
        action: 'application-started',
        location: formTealiumLocation,
        label: APPLY_BTN_LABEL,
      },
      updates,
    );

    history.push(STEP_CONTACT_DETAILS);
  };

  const { submit, loading } = useInitApplication({
    onSuccess: handleSubmitSuccess,
    onError: handleErrorCallback,
  });

  const { validateOffer } = useValidateOffers({
    onError: handleErrorCallback,
  });

  const onSubmit = ({ errors }) => {
    function initialiseApplication() {
      const applicationStartDate = new Date().toISOString();
      const newAcquisition = {
        ...storeState.acquisition,
        overview: {
          consentGiven: true,
        },
      };
      updateStore({ applicationStartDate, acquisition: newAcquisition });
      submit(newAcquisition.overview);
    }
    submitHandler({ submit: initialiseApplication, errors });
  };

  const retrySubmit = () => {
    submit(storeState.acquisition.overview);
  };

  const openFeatureAndBenefitsModal = () => {
    setFeatureAndBenefitsModalOpen(true);
    trackEvent({
      event: {
        category: 'text-link',
        action: 'interaction',
        location: pageTealiumLocation,
        label: 'View features and benefits',
      },
      ...getDataLayerElements(storeState),
    });
  };

  const closeFeatureAndBenefitsModal = () => {
    setFeatureAndBenefitsModalOpen(false);
  };

  const getLinkWithAnalytics = ({ label, url }) => {
    const gemid2 = getQueryString({
      event: {
        category: 'text-link',
        action: 'internal-link',
        location: 'what-to-expect',
        label,
      },
    });

    const link = new URL(url);
    link.searchParams.set('gemid2', gemid2);
    return link.href;
  };

  if (storeState.applicationErrors) {
    const retry = [APOLLO_NETWORK_ERROR, APOLLO_UNKNOWN_ERROR].includes(storeState.applicationErrors.type)
      ? retrySubmit
      : null;
    return (
      <LayoutPage hideBackButton>
        <Errors retry={retry} retrying={loading} isPreSubmission />
      </LayoutPage>
    );
  }

  return (
    <LayoutPage noSessionTimeout={!storeState.acquisition.overview.consentGiven} hideBackButton noChildrenPadding>
      <FeaturesAndBenefitsModal
        trackEventSender={trackEventSender}
        getLinkWithAnalytics={getLinkWithAnalytics}
        isOpen={featureAndBenefitsModalOpen}
        onClose={closeFeatureAndBenefitsModal}
      />

      <LandingSubHeader {...storeState.applyAndBuy} />
      <Banner>
        <Container width="100%" padding="0" padding-lg="0">
          <HeaderBannerTextArea openFeatureAndBenefitsModal={openFeatureAndBenefitsModal} />
          <BannerImage theme={theme}>
            <img
              src={
                ['md', 'lg', 'xl'].includes(breakpoint)
                  ? byCard({
                      [PRODUCT_CODE_AU_GEM]: GemBanner,
                      [PRODUCT_CODE_GO]: GoBanner,
                      [PRODUCT_CODE_AFS]: AfsBanner,
                      [PRODUCT_CODE_TWENTY_EIGHT_DEGREES]: Au28DBanner,
                      [PRODUCT_CODE_LOW_RATE]: LowRateBanner,
                    })
                  : byCard({
                      [PRODUCT_CODE_AU_GEM]: GemBannerMobile,
                      [PRODUCT_CODE_GO]: GoBanner,
                      [PRODUCT_CODE_AFS]: AfsCardImg,
                      [PRODUCT_CODE_TWENTY_EIGHT_DEGREES]: Au28DBannerMobile,
                      [PRODUCT_CODE_LOW_RATE]: LowRateBannerMobile,
                    })
              }
              alt="LFrame card"
            />
          </BannerImage>
        </Container>
        <div className="banner-right" />
      </Banner>
      <Container padding="0 5 5 5" paddingMd="0">
        <Form id={FORM_ID} initialValues={initialValues} onSubmit={onSubmit} hideFormButtons disableFormCache>
          {formData => {
            return (
              <ContentWrapper theme={theme}>
                <Container bg="white" margin="auto">
                  <Grid cols="10" colsGap="8">
                    <Grid.Item colSpan="all" colSpan-md="0-3">
                      <OfferTile />
                      <ApplicationOverview formData={formData} />
                    </Grid.Item>
                    <Grid.Item colSpan="all" colSpan-md="4-10">
                      <ExplainAndStart
                        trackEventSender={trackEventSender}
                        formTealiumLocation={formTealiumLocation}
                        theme={theme}
                        isAdblockCheckComplete={isAdblockCheckComplete}
                        isAdblockerDetected={isAdblockerDetected}
                        loading={loading}
                        formData={formData}
                      />
                      <Text textStyle="body2">
                        {byCard({
                          [PRODUCT_CODE_AFS]: null,
                          [PRODUCT_CODE_TWENTY_EIGHT_DEGREES]: null,
                          [PRODUCT_CODE_LOW_RATE]: null,
                          default: '*T&Cs, monthly credit card fee and other charges apply. Exclusions may apply.',
                        })}
                      </Text>
                    </Grid.Item>
                  </Grid>
                </Container>
              </ContentWrapper>
            );
          }}
        </Form>
      </Container>
    </LayoutPage>
  );
}
