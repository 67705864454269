import React from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Grid } from 'basis';
import { useTheme as emotionUseTheme } from '@emotion/react';
import { isAU, submitHandler } from 'utils';
import { AGREE_AND_CONTINUE_BTN_LABEL, STEP_CONTACT_DETAILS, STEP_AGREE_AND_CONTINUE } from '_config';
import {
  useDataLayerAndAnalyticsLinks,
  useInitApplication,
  useErrorTrackingAndUpdateStore,
  useFormInitialValues,
} from 'hooks';
import { LayoutPage, Form, Errors } from 'components';
import { useStoreValue, APOLLO_NETWORK_ERROR, APOLLO_UNKNOWN_ERROR } from 'store';
import { StartApplicationButton } from 'views/landing-page-au/StartApplicationButton';
import { Explain } from 'views/landing-page-au/Explain';
import { ContentWrapper } from '../landing-page-au/LandingPageAu.styles';

const FORM_ID = 'overview';
const formTealiumLocation = 'agreement';

export const AgreementPage = () => {
  const theme = emotionUseTheme();
  const [storeState, updateStore] = useStoreValue();
  const { trackEventSender } = useDataLayerAndAnalyticsLinks(storeState);
  const { handleErrorCallback } = useErrorTrackingAndUpdateStore();
  const initialValues = useFormInitialValues(FORM_ID);

  const onAgreeAndContinue = data => {
    const updates = {
      applicationRef: data.applicationRef,
      applicationId: data.applicationId,
      isResuming: false,
    };
    updateStore({
      activeStep: STEP_AGREE_AND_CONTINUE,
      ...updates,
    });
    trackEventSender(
      {
        category: 'application',
        action: 'application-started',
        location: formTealiumLocation,
        label: AGREE_AND_CONTINUE_BTN_LABEL,
      },
      updates,
    );
    history.push(STEP_CONTACT_DETAILS);
  };

  const noProgressStepper = isAU();
  const history = useHistory();

  const { submit, loading } = useInitApplication({
    onSuccess: onAgreeAndContinue,
    onError: handleErrorCallback,
  });

  const onSubmit = ({ errors }) => {
    function initialiseApplication() {
      const applicationStartDate = new Date().toISOString();
      const newAcquisition = {
        ...storeState.acquisition,
        overview: {
          consentGiven: true,
        },
      };
      updateStore({ applicationStartDate, acquisition: newAcquisition });
      submit(newAcquisition.overview);
    }
    submitHandler({ submit: initialiseApplication, errors });
  };

  const retrySubmit = () => {
    submit(storeState.acquisition.overview);
  };

  if (storeState.applicationErrors) {
    const retry = [APOLLO_NETWORK_ERROR, APOLLO_UNKNOWN_ERROR].includes(storeState.applicationErrors.type)
      ? retrySubmit
      : null;
    return (
      <LayoutPage hideBackButton>
        <Errors retry={retry} retrying={loading} isPreSubmission />
      </LayoutPage>
    );
  }

  return (
    <>
      <LayoutPage noProgressStepper={noProgressStepper} hideTitleIfErrors={false}>
        <Container padding="0 5 5 5" paddingMd="0">
          <Form id={FORM_ID} initialValues={initialValues} onSubmit={onSubmit} hideFormButtons disableFormCache>
            {formData => {
              return (
                <ContentWrapper theme={theme}>
                  <Container bg="white" margin="auto">
                    <Grid cols="10" colsGap="8">
                      <Grid.Item colSpan="0-9" colSpan-lg="2-8">
                        <Explain trackEventSender={trackEventSender} formTealiumLocation={formTealiumLocation} />
                        <Container margin="8 0">
                          <StartApplicationButton
                            data-testid="agree-and-continue-btn"
                            backgroundColor={theme.primaryButtonColor}
                            theme={theme}
                          >
                            {AGREE_AND_CONTINUE_BTN_LABEL}
                          </StartApplicationButton>
                        </Container>
                      </Grid.Item>
                    </Grid>
                  </Container>
                </ContentWrapper>
              );
            }}
          </Form>
        </Container>
      </LayoutPage>
    </>
  );
};
