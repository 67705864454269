const latitudeId = {
  latitudeId: 'mock@email.com',
  password: '12Abc$^&',
};

const password = {
  password: 'Abc123',
};

const otherDetails = {
  residentialStatus: 'HOME_OWNER',
  lengthOfCurrentResidency: {
    years: '3',
    months: '0',
  },
  isPostalSameWithResidential: 'yes',
  hasNonDomesticPreviousAddress: false,
  currentAddress: {
    useManualAddress: false,
    address: {
      addressType: 'STREET_ADDRESS',
      poBoxNumber: '',
      propertyName: '',
      unitNumber: '',
      streetNumber: '800',
      streetName: 'Collins',
      streetType: 'Road',
      suburb: 'Docklands',
      state: 'VIC',
      postcode: '3008',
      countryCode: 'AU',
    },
  },
  previousAddress: {
    useManualAddress: false,
    address: {
      addressType: '',
      poBoxNumber: '',
      propertyName: '',
      unitNumber: '',
      streetNumber: '',
      streetName: '',
      streetType: '',
      suburb: '',
      state: '',
      postcode: '',
      countryCode: 'AU',
    },
  },
  postalAddress: {
    useManualAddress: false,
    address: {
      addressType: '',
      poBoxNumber: '',
      propertyName: '',
      unitNumber: '',
      streetNumber: '',
      streetName: '',
      streetType: '',
      suburb: '',
      state: '',
      postcode: '',
      countryCode: 'AU',
    },
  },
};

const extraDetails = {
  idType: 'AU_DRIVERS_LICENCE',
  licenceNumber: '123123',
  version: '453',
  expiryDate: {
    day: '01',
    month: '01',
    year: '2030',
  },
  australianState: 'VIC',
};

const employmentDetails = {
  industry: 'COMPUTER_STAFF',
  employerName: 'Latitude',
  lengthOfTimeAtCurrentEmployer: {
    years: '1',
    months: '0',
  },
  lengthOfUnemployment: {
    years: '',
    months: '',
  },
  income: {
    amount: '14000',
    frequency: 'monthly',
  },
  employmentStatus: 'PERMANENT_FULL_TIME',
  hasOtherIncome: 'yes',
  otherEmploymentStatus: 'CASUAL_TEMPORARY',
  otherIncome: {
    amount: '1000',
    frequency: 'monthly',
  },
  expectedIncomeChange: 'no',
  incomeAdjustmentRequired: 'no',
  conditionalStop: 'no',
};

const expensesDetails = {
  isExpensesShared: 'no',
  isSummaryExpenses: 'no',
  mortgageRentBoard: {
    amount: '1000',
    frequency: 'monthly',
  },
  foodGroceriesLifestyleEntertainment: {
    amount: '400',
    frequency: 'monthly',
  },
  insurance: {
    amount: '300',
    frequency: 'monthly',
  },
  utilities: {
    amount: '400',
    frequency: 'monthly',
  },
  educationAndChildcare: {
    amount: '500',
    frequency: 'monthly',
  },
  transport: {
    amount: '600',
    frequency: 'monthly',
  },
  personalAndOtherExpense: {
    amount: '700',
    frequency: 'monthly',
  },
  basicExpenses: {
    amount: '',
    frequency: 'monthly',
  },
  additionalExpenses: {
    amount: '',
    frequency: 'monthly',
  },
};

const additionalDebtsDetails = {
  hasOtherDebts: 'no',
  secondaryPropertyMortgageRentBoard: {
    amount: '',
    frequency: 'monthly',
  },
  totalCardLimits: '',
  amountOwingOnCreditCard: '',
  loanRepayment: {
    amount: '',
    frequency: 'monthly',
  },
  hasLoanLumpSumPayment: 'no',
  payLumpSumWithExistingSaving: '',
};

const confirmCreditLimit = {
  empty: {
    newCreditLimit: '',
    creditLimitOptions: null,
  },
  valid: {
    newCreditLimit: '',
    creditLimitOptions: 'acceptCreditLimit',
  },
  declined: {
    creditLimit: '',
  },
  referred: {
    creditLimit: '',
  },
  conditionallyApproved: {
    creditLimit: '',
  },
};

const preferredCreditLimitDetails = {
  valid: {
    creditLimit: '5000',
  },
  declined: {
    creditLimit: '2000',
  },
  referred: {
    creditLimit: '2000',
  },
  conditionallyApproved: {
    creditLimit: '2000',
  },
};

const acceptance = {
  accepted: true,
};

const achDetails = {
  hasAch: 'yes',
  sameCurrentAddress: 'no',
  personalDetails: {
    title: 'MRS',
    firstName: 'Rachel',
    middleName: 'R',
    lastName: 'Madams',
    dateOfBirth: {
      day: '1',
      month: '8',
      year: '1975',
    },
    hasMiddleName: 'yes',
    mobileNumber: '0400000001',
    emailAddress: 'mock@email.com',
  },
  currentAddress: {
    useManualAddress: false,
    address: {
      addressType: 'STREET_ADDRESS',
      poBoxNumber: '',
      propertyName: '',
      unitNumber: '',
      streetNumber: '801',
      streetName: 'Collins',
      streetType: 'Road',
      suburb: 'Docklands',
      state: 'VIC',
      postcode: '3008',
      countryCode: 'AU',
    },
  },
};

const contactDetails = {
  mobileNumber: '0400000000',
  emailAddress: 'mock@email.com',
  confirmEmail: 'mock@email.com',
};

const personalDetails = {
  title: 'MR',
  firstName: 'Rafael',
  middleName: 'Kingsley Dom',
  lastName: 'Madams',
  dateOfBirth: {
    day: '25',
    month: '12',
    year: '1973',
  },
  hasAuDL: 'yes',
  hasMiddleName: 'yes',
  australianState: 'VIC',
  driverLicenceNumber: '1234567',
  driverLicenceCardNumber: 'AB123456',
  relationshipStatus: 'MARRIED_DEFACTO',
  numberOfDependents: '0',
  crbConsent: true,
  dvsConsent: true,
};

export const auAcquisitionComplete = {
  overview: {
    promoCode: '',
    consentGiven: true,
    hasReadImportantDocuments: true,
    applicationReason: '',
    conditionalStop: '',
  },
  contactDetails,
  latitudeId,
  password,
  verification: {
    verificationCode: '',
  },
  emailVerification: {
    verificationCode: '',
  },
  personalDetails,
  otherDetails,
  extraDetails,
  employmentDetails,
  expensesDetails,
  additionalDebtsDetails,
  confirmCreditLimit: confirmCreditLimit.empty,
  preferredCreditLimitDetails,
  acceptance,
  achDetails,
};
