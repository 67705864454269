import React from 'react';
import styled from '@emotion/styled';
import { Container, Grid, Link, Stack, Text } from 'basis';
import { LayoutPage } from 'components';
import AdrianaMartinezSignature from 'images/adriana_martinez.png';
import { davidJonesTheme } from '../../../../theme';
import { StyledULWideLeftPadding as StyledUL, ballotBoxWithX, ballotBoxWithTick, LightHr } from './Styles';

export function GoFinancialTable({
  ratesAndFees,
  statementDate,
  downloadOrPrintFile,
  productName,
  accountNumber,
  creditLimit,
}) {
  const {
    purchaseAnnualRate,
    expiredPromotionalPurchaseRate,
    monthlyFee,
    cashAdvanceFeePercentagePerTransaction,
    cashAdvanceFeeFixed,
    lateFee,
    paperStatementFee,
    ausPostHandlingFee,
    onlinePaymentHandlingFee,
    balanceTransferringFee,
    internationalCashAccessFee,
    internationalCounterTransactionFee,
    internationalTransactionFee,
  } = ratesAndFees;

  return (
    <LayoutPage hideBackButton>
      <Text>
        <Container margin="0 0 0 0">
          <Container padding="5 0" padding-md="5" testId="LAYOUT FORM">
            <Grid preset="page" debug={false}>
              <Grid.Item colSpan="all">
                <Grid rowsGap={0}>
                  <Container padding="4 4" margin="0 8">
                    <div style={{ marginBottom: '40px' }}>
                      <Link variant="secondary" id="pdfButton" onClick={downloadOrPrintFile}>
                        Download or Print file
                      </Link>
                    </div>
                    <Grid preset="page" colsGap={11}>
                      <Grid.Item colSpan="all">
                        <Grid rowsGap={1}>
                          <Container padding="0">
                            <div id="financial-table">
                              <Stack gap="1">
                                <Text
                                  as="h1"
                                  textStyle="heading5"
                                  textStyle-md="heading4"
                                  color={davidJonesTheme.headingColor}
                                >
                                  {productName} Contract Schedule
                                </Text>
                                <br />
                                <Text textStyle="heading5">Offer to New {productName} Account Holders</Text>
                                <Text>
                                  Latitude Finance Australia ABN 42 008 583 588 Australian Credit Licence number 392145,
                                  as the credit provider, offers to provide you with a {productName} credit card and
                                  account on the conditions set out in the Credit Contract.
                                </Text>
                                <br />
                                <Text>Signed on behalf of Latitude Finance Australia.</Text>
                                <Container padding="4 0">
                                  <img src={AdrianaMartinezSignature} width="150px" alt="form-title-icon" />
                                  <Text>
                                    <strong>Adriana Martinez</strong>
                                  </Text>
                                  <Text>Executive General Manager Pay, Latitude</Text>
                                </Container>
                                <Text textStyle="heading5">Overview of your {productName} credit card</Text>
                                <hr />
                                <Text>
                                  As per the Latitude Credit Card General Terms and Conditions ('General Terms and
                                  Conditions'), the following features are available through your {productName} credit
                                  card:
                                </Text>
                                <LightHr />
                                <StyledTable>
                                  <StyledTableRow>
                                    <StyledTableCell flex={0.2} style={{ fontSize: '26px' }}>
                                      {ballotBoxWithTick}
                                    </StyledTableCell>
                                    <StyledTableCell flex={1.3}>Interest Free Payment Plans</StyledTableCell>
                                    <StyledTableCell flex={4}>
                                      <Text>
                                        Available on general purchases made under payment plan offers at participating
                                        retailers:
                                      </Text>
                                      <StyledUL>
                                        <li>Equal Monthly Payment Plans</li>
                                        <li>Minimum Monthly Payment Plans</li>
                                        <li>Deferred Payment Plans</li>
                                      </StyledUL>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledTableCell flex={0.2} style={{ fontSize: '26px' }}>
                                      {ballotBoxWithX}
                                    </StyledTableCell>
                                    <StyledTableCell flex={1.3}>
                                      Instalment Offer Plans (interest bearing)
                                    </StyledTableCell>
                                    <StyledTableCell flex={4}>Not available</StyledTableCell>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledTableCell flex={0.2} style={{ fontSize: '26px' }}>
                                      {ballotBoxWithTick}
                                    </StyledTableCell>
                                    <StyledTableCell flex={1.3}>International transactions</StyledTableCell>
                                    <StyledTableCell flex={4}>Available</StyledTableCell>
                                  </StyledTableRow>
                                </StyledTable>
                                <br />
                                <Text textStyle="heading5">Financial table</Text>
                                <hr />
                                <Text>
                                  The details below are correct as at the disclosure date. The financial table does not
                                  contain all of the pre-contractual information we are required by law to give you.
                                  Further terms and conditions (and an explanation of some of the words used in the
                                  financial table) are in the General Terms and Conditions. You must read this Contract
                                  Schedule together with the General Terms and Conditions.
                                </Text>
                                <br />
                                <StyledTable>
                                  <StyledTableRow>
                                    <StyledTableCell flex={1.3}>Account number</StyledTableCell>
                                    <StyledTableCell flex={1.3}>Disclosure date</StyledTableCell>
                                    <StyledTableCell flex={1.3}>Credit limit</StyledTableCell>
                                    <StyledTableCell flex={1.3}>Credit sub-limit</StyledTableCell>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledTableCell flex={1.3}>
                                      <data-hj-suppress>
                                        <Text>{accountNumber}</Text>
                                      </data-hj-suppress>
                                    </StyledTableCell>
                                    <StyledTableCell flex={1.3}>
                                      <Text>{statementDate}</Text>
                                    </StyledTableCell>
                                    <StyledTableCell flex={1.3}>
                                      <data-hj-suppress>
                                        <Text>${creditLimit}</Text>
                                      </data-hj-suppress>
                                    </StyledTableCell>
                                    <StyledTableCell flex={1.3}>
                                      <Text>N/A</Text>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                </StyledTable>
                                <br />
                                <Text textStyle="heading5">
                                  Cash limit <i style={{ color: 'grey' }}>(refer to your statement)</i>
                                </Text>
                                <hr />
                                <Text>
                                  A cash limit forms part of your credit limit and may be withdrawn in cash or cash
                                  equivalent transactions. In each statement we give you, we may notify you of any cash
                                  limit we impose, withdraw or change from time to time.
                                </Text>
                                <br />
                                <Text textStyle="heading5">Annual percentage rates</Text>
                                <hr />
                                <StyledTable>
                                  <StyledTableRow>
                                    <StyledTableCell flex={3}>
                                      Purchase Rate - applies to the general purchase balance
                                    </StyledTableCell>
                                    <StyledTableCell flex={1.3} style={{ textAlign: 'right' }}>
                                      {purchaseAnnualRate}% p.a.
                                    </StyledTableCell>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledTableCell flex={3}>
                                      Cash Advance Rate - applies to the cash advance balance
                                    </StyledTableCell>
                                    <StyledTableCell flex={1.3} style={{ textAlign: 'right' }}>
                                      {cashAdvanceFeePercentagePerTransaction}% p.a.
                                    </StyledTableCell>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledTableCell flex={3}>
                                      Expired Plan Rate - applies to expired Plan balance
                                    </StyledTableCell>
                                    <StyledTableCell flex={1.3} style={{ textAlign: 'right' }}>
                                      {expiredPromotionalPurchaseRate}% p.a.
                                    </StyledTableCell>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledTableCell flex={3}>
                                      Balance Transfer Rate - applies to balance transfer offers
                                    </StyledTableCell>
                                    <StyledTableCell flex={1.3} style={{ textAlign: 'right' }}>
                                      The Cash Advance Rate applies, unless a specific balance transfer rate is
                                      subsequently offered
                                    </StyledTableCell>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledTableCell flex={3}>
                                      Instalment Offer Rate - applies to instalment offers
                                    </StyledTableCell>
                                    <StyledTableCell flex={1.3} style={{ textAlign: 'right' }}>
                                      The Purchase Rate applies, unless a specific instalment offer rate is subsequently
                                      offered
                                    </StyledTableCell>
                                  </StyledTableRow>
                                </StyledTable>
                                <br />
                                <Text textStyle="heading5">Interest free periods</Text>
                                <hr />
                                <StyledTable>
                                  <StyledTableRow>
                                    <StyledTableCell flex={1.3}>Up to 55 days</StyledTableCell>
                                    <StyledTableCell flex={3}>
                                      <ol style={{ marginLeft: '-20px', marginTop: '-4px' }}>
                                        <li>
                                          General purchases of goods and services (not cash advances) may (subject to
                                          clause 2.), be interest free up to 55 days from the transaction date.
                                        </li>
                                        <li>
                                          We do not charge interest on the general purchase balance for a statement
                                          period if during the previous statement period:
                                          <ol type="a" style={{ marginLeft: '-24px' }}>
                                            <li>
                                              you pay by the due date the modified closing balance, for the statement of
                                              account issued at the start of that statement period; or
                                            </li>
                                            <li>
                                              that statement of account did not require you to make a minimum monthly
                                              payment.
                                            </li>
                                          </ol>
                                          If you do not meet the requirements under this clause for a statement period,
                                          interest will be calculated using the Purchase Rate and charged on any
                                          outstanding amount of the general purchase balance during that statement
                                          period.
                                        </li>
                                      </ol>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledTableCell flex={1.3}>6 months</StyledTableCell>
                                    <StyledTableCell flex={3}>
                                      General purchase of goods and services (not cash advances) of $250 or more in the
                                      one transaction, may, at your option, be selected within 30 days from the posted
                                      date of that purchase for an interest free period of 6 months subject to the terms
                                      of a Minimum Monthly Payment Plan.
                                      <br />
                                      <br />
                                      The 6-month period will start from the date the transaction is selected.
                                      <br />
                                      <br />
                                      We will charge interest calculated using the Purchase Rate on any outstanding
                                      amount after the 6-month period.
                                    </StyledTableCell>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledTableCell flex={1.3}>6-60 months</StyledTableCell>
                                    <StyledTableCell flex={3}>
                                      <Text>
                                        Available on general purchases made under payment plan offers at participating
                                        retailers subject to the following types of Interest Free Payment Plans:
                                      </Text>
                                      <StyledUL>
                                        <li>
                                          Equal Monthly Payment Plans - see clause 10.8 of the General Terms and
                                          Conditions.
                                        </li>
                                        <li>
                                          Minimum Monthly Payment Plans - see clause 10.10 of the General Terms and
                                          Conditions.
                                        </li>
                                        <li>
                                          Deferred Payment Plans - see clause 10.12 of the General Terms and Conditions.
                                        </li>
                                      </StyledUL>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                </StyledTable>
                                <br />
                                <Text textStyle="heading5">Payments explained</Text>
                                <hr />
                                <StyledTable>
                                  <StyledTableRow>
                                    <StyledTableCell flex={1.3}>Minimum monthly payment</StyledTableCell>
                                    <StyledTableCell flex={3}>
                                      <Text>
                                        The minimum monthly payment for a statement period is an amount equal to the sum
                                        of:
                                      </Text>
                                      <StyledUL>
                                        <li>
                                          any instalment payments due in that period under any unexpired Instalment
                                          Plans;
                                        </li>
                                        <li>
                                          the greater of $25 or 3% of the closing balance, shown for the statement
                                          period on your statement of account:
                                          <ul>
                                            <li>
                                              including the balance of Minimum Monthly Payment Plans, balance transfer
                                              offers or any expired payment plans;
                                            </li>
                                            <li>
                                              but excluding any outstanding amounts on any Instalment Plans or any
                                              Deferred Payment Plans that have not expired on or before the due date.
                                            </li>
                                          </ul>
                                        </li>
                                      </StyledUL>
                                      <Text>
                                        If your outstanding balance is less than $25, you must pay that balance.
                                      </Text>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledTableCell flex={1.3}>Modified closing balance</StyledTableCell>
                                    <StyledTableCell flex={3}>
                                      <Text>
                                        The modified closing balance is the amount you need to pay by the due date to
                                        qualify for interest free days on general purchases.
                                      </Text>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                </StyledTable>
                                <br />
                                <Text textStyle="heading5">Fees and charges</Text>
                                <hr />
                                <StyledTable>
                                  <StyledTableRow>
                                    <StyledTableCell flex={3}>
                                      A credit card fee will be charged monthly
                                    </StyledTableCell>
                                    <StyledTableCell flex={1.3} style={{ textAlign: 'right' }}>
                                      ${monthlyFee}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledTableCell flex={3}>
                                      <Text>
                                        A cash advance fee will be charged on each occasion on which you obtain a cash
                                        advance or make a cash equivalent transaction.
                                      </Text>
                                    </StyledTableCell>
                                    <StyledTableCell flex={1.3} style={{ textAlign: 'right' }}>
                                      Greater of ${cashAdvanceFeeFixed} or {cashAdvanceFeePercentagePerTransaction}% of
                                      the cash advance amount
                                    </StyledTableCell>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledTableCell flex={3}>
                                      <Text>
                                        An international cash access fee will be charged in addition to the cash advance
                                        fee on each occasion you obtain cash from an ATM outside Australia.
                                      </Text>
                                    </StyledTableCell>
                                    <StyledTableCell flex={1.3} style={{ textAlign: 'right' }}>
                                      ${internationalCashAccessFee?.toFixed(2)}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledTableCell flex={3}>
                                      <Text>
                                        An international counter transaction fee will be charged in addition to the cash
                                        advance fee on each occasion you obtain a cash advance or make a cash equivalent
                                        transaction outside Australia other than from an ATM.
                                      </Text>
                                    </StyledTableCell>
                                    <StyledTableCell flex={1.3} style={{ textAlign: 'right' }}>
                                      ${internationalCounterTransactionFee?.toFixed(2)}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledTableCell flex={3}>
                                      <Text>
                                        An international transaction fee of a specific percentage of the amount of the
                                        international transaction (as converted to Australian dollars if not originally
                                        in Australian dollars) will be charged when we process each international
                                        transaction.
                                      </Text>
                                    </StyledTableCell>
                                    <StyledTableCell flex={1.3} style={{ textAlign: 'right' }}>
                                      {internationalTransactionFee?.toFixed(2)}% of the Australian dollar value of the
                                      international transaction
                                    </StyledTableCell>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledTableCell flex={3}>
                                      <Text>
                                        A balance transfer fee will be charged if you take up a balance transfer offer.
                                      </Text>
                                    </StyledTableCell>
                                    <StyledTableCell flex={1.3} style={{ textAlign: 'right' }}>
                                      Up to {balanceTransferringFee}% of balance transfer amount or in accordance with
                                      the specific balance transfer offer
                                    </StyledTableCell>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledTableCell flex={3}>
                                      <Text>
                                        A late fee will be charged in each statement period when any amount due and
                                        payable to us is not paid in full by the due date.
                                      </Text>
                                    </StyledTableCell>
                                    <StyledTableCell flex={1.3} style={{ textAlign: 'right' }}>
                                      ${lateFee?.toFixed(2)}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledTableCell flex={3}>
                                      <Text>
                                        A paper statement fee will be charged for each paper statement of account sent
                                        to you. This fee will not be charged if you consent to receiving your statements
                                        electronically.
                                      </Text>
                                    </StyledTableCell>
                                    <StyledTableCell flex={1.3} style={{ textAlign: 'right' }}>
                                      ${paperStatementFee?.toFixed(2)}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledTableCell flex={3}>
                                      <Text>
                                        A payment handling fee will be charged each time you make a payment to your
                                        account, except that no fee is payable for payments made by mail, by direct
                                        debit or via the Latitude Service Centre.
                                      </Text>
                                    </StyledTableCell>
                                    <StyledTableCell flex={1.3} style={{ textAlign: 'right' }}>
                                      ${ausPostHandlingFee?.toFixed(2)} (in person)
                                      <br />${onlinePaymentHandlingFee?.toFixed(2)} (online)
                                    </StyledTableCell>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledTableCell flex={3}>
                                      <Text>
                                        A credit balance processing fee will be charged when your account is closed and
                                        still holds a credit balance of less than $20.00; unless an instruction is
                                        provided to us on how to return the credit balance to you.
                                      </Text>
                                    </StyledTableCell>
                                    <StyledTableCell flex={1.3} style={{ textAlign: 'right' }}>
                                      The fee amount will be the lesser of your balance or $19.99
                                    </StyledTableCell>
                                  </StyledTableRow>
                                </StyledTable>
                                <Text>
                                  These fees and charges will be charged and debited to your account and will form part
                                  of the closing balance which is used to calculate your minimum monthly payments.
                                </Text>
                                <br />
                                <Container>
                                  <Text textStyle="heading5">Changes to the Credit Contract</Text>
                                </Container>
                                <hr />
                                <Text>
                                  Subject to applicable law, we may change the variable annual percentage rate(s),
                                  maximum interest free period, the amount, frequency or time for payment or method of
                                  calculation of a fee or charge or minimum monthly payment, reduce the credit limit, or
                                  impose new fees or charges without your consent where we have determined it is
                                  reasonably necessary to protect our legitimate interests. You will be notified of
                                  changes or new fees or charges in the manner set out in the General Terms and
                                  Conditions. If you are not happy with any changes made to your credit contract, you
                                  may close your credit contract in accordance with clause 28.6 of the General Terms and
                                  Conditions.
                                </Text>
                                <br />
                                <Text textStyle="heading5">Commission</Text>
                                <hr />
                                <Text>
                                  An unascertainable commission may be payable for the introduction of this credit
                                  business to any merchant and related entity of a merchant which is involved in the
                                  introduction.
                                </Text>
                              </Stack>
                            </div>
                          </Container>
                        </Grid>
                      </Grid.Item>
                    </Grid>
                  </Container>
                </Grid>
              </Grid.Item>
            </Grid>
          </Container>
        </Container>
      </Text>
    </LayoutPage>
  );
}

const StyledTable = styled.div`
  width: 100%;
  margin: 20px 0;
`;

const StyledTableRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid #e0e0e0;

  &:last-child {
    border-bottom: none;
  }
`;

const StyledTableCell = styled.div`
  flex: ${props => props.flex || 1};
  padding: 0 8px;

  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    padding-right: 0;
  }
`;
