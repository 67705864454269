import React from 'react';
import { Text, useBreakpoint, Button, Container } from 'basis';
import { ListItem } from '@chakra-ui/react';
import { byCard } from 'utils/byConfig';
import { PRODUCT_CODE_AFS } from '_config';
import { OverviewBox } from './LandingPageAu.styles';
import { UnorderedList } from './Explain';

export const AccordionWhenXS = ({ children }) => {
  const breakpoint = useBreakpoint();
  if (breakpoint !== 'default' && breakpoint !== 'xs') {
    return children;
  }
  return null;
};

export const ApplicationOverviewGemVisaAu = ({ openFeatureAndBenefitsModal, theme }) => {
  const featureBenefits = [
    'Instant 0% interest on $250+ purchases for 6 months',
    '0% Interest Free plans from 6 – 60 months at 100s of retailers online and in-store',
    'Enjoy 6% off with Booking.com and 10% off with Expedia and Wotif',
  ];
  const buttonLabel = byCard({
    [PRODUCT_CODE_AFS]: 'View all features and rates',
    default: 'View all features and rates',
  });

  return (
    <>
      <OverviewBox theme={theme}>
        <Text textStyle="heading5" as="h4" margin="0 0 0 1">
          Featured benefits
        </Text>
        <UnorderedList>
          {featureBenefits.map((benefit, index) => (
            <ListItem key={`featured-benefit-item-${index}`}>
              <Text>{benefit}</Text>
            </ListItem>
          ))}
        </UnorderedList>
        <Container>
          <Button
            variant="secondary"
            data-testid="application-overview-btn"
            type="button"
            onClick={openFeatureAndBenefitsModal}
          >
            {buttonLabel}
          </Button>
        </Container>
      </OverviewBox>
    </>
  );
};
