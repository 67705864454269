import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { setSessionStorage } from 'utils';
import { storageStateKey, useStoreValue } from 'store';
import { STEP_OUTCOME, config } from '_config';
import { defaultsDeep } from 'lodash';
import { getDefaultState } from 'store/initialState';
import { auAcquisitionComplete } from './mockState';
import {
  DECLINED,
  REFERRED,
  PENDING,
  APPROVED,
  SERVICE_UNAVAILABLE,
  POI_CHECK_REQUIRED,
  POI_CHECK_PENDING,
  CONDITIONALLY_APPROVED,
  PROOF_OF_ADDRESS,
  ANTI_MONEY_LAUNDERING,
  PROOF_OF_INCOME,
  PROOF_OF_ADDRESS_AND_PROOF_OF_INCOME,
  ANTI_MONEY_LAUNDERING_AND_PROOF_OF_INCOME,
  BIOMETRICS_CHECK_REQUIRED,
  IDV_CHECK_REQUIRED,
  IDV_CHECK_PENDING,
  CCR_REQUIRED,
  QUOTE_INITIAL,
  QUOTE_DECLINE,
  POITYPE_DOC_UPLOAD,
  POITYPE_PAYSLIP_UPLOAD,
} from '../store/constants';

import {
  STEP_LOAN_INFORMATION,
  STEP_CONFIRM_CREDIT_LIMIT,
  STEP_DVS_CONSENT,
  STEP_PROCESSING_IDV,
  STEP_EIDV,
  STEP_AUS_POST,
  STEP_VERIFY_INSTORE,
} from '../_config';

export function DevTeleport({ setAllowNavigationToAllRoutes }) {
  const [, updateState] = useStoreValue();
  const teleport = useCallback(
    async index => {
      setAllowNavigationToAllRoutes();
      const selectedOption = options[config.countryCode][index];
      const state = buildState(selectedOption.getState());
      setSessionStorage(storageStateKey, state);
      updateState(state);
      window.location.href = selectedOption.path;
    },
    [setAllowNavigationToAllRoutes, updateState],
  );

  return (
    <Wrapper>
      {options[config.countryCode].map(({ label }, index) => (
        <button key={label} onClick={() => teleport(index)} type="button">
          {label}
        </button>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border: 1px solid #d6ebff;

  button {
    padding: 0.5rem 1rem;
    background-color: #d6ebff;
    border: none;
    cursor: pointer;
    font-weight: 600;
  }
`;

const options = {
  AU: [
    {
      label: 'Saved for later',
      path: '/saved',
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        applicationStatus: POI_CHECK_REQUIRED,
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
        gemVisaAccountNumber: '5218 9435 0015 199',
      }),
    },
    {
      label: 'Resume - Expired',
      path: '/resume-application-expired',
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationStatus: IDV_CHECK_REQUIRED,
        isEmailVerified: true,
        isLatitudeIdPasswordSet: true,
        applicationSubmitted: true,
        applicationStartDate: new Date().toISOString(),
        applicationErrors: null,
        creditLimitApproved: 3000,
      }),
    },
    {
      label: 'Commitments',
      path: '/commitments',
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: false,
        applicationStartDate: new Date().toISOString(),
      }),
    },
    {
      label: 'Additional Card Holder',
      path: '/additional-card-holder',
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: false,
        applicationStartDate: new Date().toISOString(),
      }),
    },
    {
      label: 'Processing Submission',
      path: '/processing',
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        applicationStatus: PENDING,
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
      }),
    },
    {
      label: 'CCR Loan Information',
      path: STEP_LOAN_INFORMATION,
      getState: () => ({
        acquisition: {
          ...auAcquisitionComplete,
          otherDetails: {
            ...auAcquisitionComplete.otherDetails,
            residentialStatus: 'MORTGAGE',
          },
        },
        applicationStatus: CCR_REQUIRED,
        applicationSubmitted: true,
        applicationStartDate: new Date().toISOString(),
        applicationErrors: null,
        undisclosedDebts: [
          {
            id: 1,
            source: 'EQUIFAX_AUSTRALIA',
            category: 'HOME_LOAN',
            creditProvider: 'ANZ',
            currentLimit: 10000.0,
          },
          {
            id: 2,
            source: 'EQUIFAX_AUSTRALIA',
            category: 'HOME_LOAN',
            creditProvider: 'ANZ',
            currentLimit: 20000.0,
          },
          {
            id: 3,
            source: 'EQUIFAX_AUSTRALIA',
            category: 'CREDIT_CARD',
            creditProvider: 'Latitude',
            currentLimit: 3000.0,
          },
        ],
      }),
    },
    {
      label: 'Dynamic Credit Limit - Conditionally Approved',
      path: STEP_CONFIRM_CREDIT_LIMIT,
      getState: () => ({
        acquisition: {
          ...auAcquisitionComplete,
          confirmCreditLimit: { newCreditLimit: '', creditLimitOptions: null },
        },
        applicationStatus: QUOTE_INITIAL,
        applicationSubmitted: true,
        applicationStartDate: new Date().toISOString(),
        applicationErrors: null,
        creditLimitApproved: 7000,
      }),
    },
    {
      label: 'Dynamic Credit Limit - Declined',
      path: STEP_CONFIRM_CREDIT_LIMIT,
      getState: () => ({
        acquisition: {
          ...{
            overview: {
              consentGiven: true,
              hasReadImportantDocuments: true,
            },
            contactDetails: {},
            password: {},
            personalDetails: {},
            otherDetails: {},
            extraDetails: {},
            employmentDetails: {},
            expensesDetails: {},
            additionalDebtsDetails: {},
            confirmCreditLimit: { referred: true },
            preferredCreditLimitDetails: { referred: true },
          },
          confirmReferredCreditLimit: {
            newCreditLimit: '',
          },
        },
        applicationStatus: QUOTE_DECLINE,
        applicationSubmitted: true,
        applicationStartDate: new Date().toISOString(),
        applicationErrors: null,
      }),
    },
    {
      label: 'Dynamic Credit Limit - Conditional Approved - Min Limit DJ Premiere',
      path: STEP_CONFIRM_CREDIT_LIMIT,
      getState: () => ({
        acquisition: {
          ...auAcquisitionComplete,
          confirmCreditLimit: { newCreditLimit: '', creditLimitOptions: null },
        },
        applicationStatus: QUOTE_INITIAL,
        applicationSubmitted: true,
        applicationStartDate: new Date().toISOString(),
        applicationErrors: null,
        creditLimitApproved: 1000,
      }),
    },
    {
      label: 'Dynamic Credit Limit - Conditional Approved - Min Limit DJ Prestige',
      path: STEP_CONFIRM_CREDIT_LIMIT,
      getState: () => ({
        acquisition: {
          ...auAcquisitionComplete,
          confirmCreditLimit: { newCreditLimit: '', creditLimitOptions: null },
        },
        applicationStatus: QUOTE_INITIAL,
        applicationSubmitted: true,
        applicationStartDate: new Date().toISOString(),
        applicationErrors: null,
        creditLimitApproved: 6000,
      }),
    },
    {
      label: 'Latitude ID - Introduction',
      path: '/latitude-id-introduction',
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        applicationStatus: IDV_CHECK_REQUIRED,
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
        isEmailVerified: false,
        isLatitudeIdPasswordSet: false,
      }),
    },
    {
      label: 'Latitude ID - Password',
      path: '/latitude-id',
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        applicationStatus: IDV_CHECK_REQUIRED,
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
        isEmailVerified: true,
        isLatitudeIdPasswordSet: false,
      }),
    },
    {
      label: 'Proof of Identity - EIDV - DVS Consent Required',
      path: STEP_DVS_CONSENT,
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        applicationStatus: IDV_CHECK_REQUIRED,
        isEmailVerified: true,
        isLatitudeIdPasswordSet: true,
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
      }),
    },
    {
      label: 'Proof of Identity - EIDV - choose methods',
      path: STEP_EIDV,
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationStatus: IDV_CHECK_REQUIRED,
        isEmailVerified: true,
        isLatitudeIdPasswordSet: true,
        applicationSubmitted: true,
        applicationStartDate: new Date().toISOString(),
        applicationErrors: null,
      }),
    },
    {
      label: 'Proof of Identity - EIDV - Pending',
      path: STEP_PROCESSING_IDV,
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        applicationStatus: IDV_CHECK_PENDING,
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
      }),
    },
    {
      label: 'Proof of Identity - Processing Biometrics',
      path: '/processing-biometrics',
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        applicationStatus: BIOMETRICS_CHECK_REQUIRED,
        applicationErrors: null,
        isEmailVerified: true,
        isLatitudeIdPasswordSet: true,
        applicationStartDate: new Date().toISOString(),
        gemVisaAccountNumber: '5218 9435 0015 199',
        maskedMobileNumber: '044 *** 234',
      }),
    },
    {
      label: 'Proof of Identity - Australia Post',
      path: STEP_AUS_POST,
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationStatus: IDV_CHECK_REQUIRED,
        isEmailVerified: true,
        isLatitudeIdPasswordSet: true,
        applicationSubmitted: true,
        applicationStartDate: new Date().toISOString(),
        applicationErrors: null,
      }),
    },
    {
      label: 'Proof of Identity - In Store',
      path: STEP_VERIFY_INSTORE,
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationStatus: IDV_CHECK_REQUIRED,
        isEmailVerified: true,
        isLatitudeIdPasswordSet: true,
        applicationSubmitted: true,
        applicationStartDate: new Date().toISOString(),
        applicationRef: '7014625177363',
        applicationErrors: null,
      }),
    },

    {
      label: 'Proof of Income - choose methods',
      path: '/verify-income',
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        applicationStatus: POI_CHECK_REQUIRED,
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
        gemVisaAccountNumber: '5218 9435 0015 199',
      }),
    },
    {
      label: 'Proof of Income - Proviso',
      path: '/verify-ficonnect',
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        applicationStatus: POI_CHECK_REQUIRED,
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
        gemVisaAccountNumber: '5218 9435 0015 199',
      }),
    },
    {
      label: 'Proof of Income - Document Upload',
      path: '/verify-document-upload',
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        applicationStatus: POI_CHECK_REQUIRED,
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
        gemVisaAccountNumber: '5218 9435 0015 199',
      }),
    },
    {
      label: 'Proof of Income - Document Upload - Timeout',
      path: '/outcome-timeout',
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        applicationStatus: POI_CHECK_PENDING,
        incomeVerificationType: POITYPE_DOC_UPLOAD,
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
        gemVisaAccountNumber: '5218 9435 0015 199',
      }),
    },
    {
      label: 'Outcome - Approved',
      path: STEP_OUTCOME,
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        applicationStatus: 'APPROVED',
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
        applicationDate: new Date().toISOString(),
        finalDecisionDate: new Date().toISOString(),
        accountNumber: '5218 9435 0015 199',
        creditLimitApproved: 3000,
        applicationRef: '7323655040880',
      }),
    },
    {
      label: 'Outcome - Declined',
      path: STEP_OUTCOME,
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        applicationStatus: DECLINED,
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
      }),
    },
    {
      label: 'Outcome - Referred',
      path: STEP_OUTCOME,
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        applicationStatus: REFERRED,
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
      }),
    },
    {
      label: 'Outcome - Timeout',
      path: '/outcome-timeout',
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        applicationStatus: PENDING,
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
      }),
    },
    {
      label: 'Outcome - System Unavailable',
      path: STEP_OUTCOME,
      getState: () => ({
        acquisition: {
          overview: {
            consentGiven: true,
            hasReadImportantDocuments: true,
          },
          contactDetails: {},
          password: {},
          personalDetails: {},
          otherDetails: {},
          extraDetails: {},
          employmentDetails: {},
          expensesDetails: {},
          additionalDebtsDetails: {},
        },
        applicationSubmitted: true,
        applicationStatus: SERVICE_UNAVAILABLE,
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
      }),
    },
    {
      label: 'Outcome - Referred + Upload Payslips',
      path: STEP_OUTCOME,
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        incomeVerificationType: POITYPE_PAYSLIP_UPLOAD,
        applicationStatus: REFERRED,
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
      }),
    },
    {
      label: 'Outcome - Referred + Upload Documents',
      path: STEP_OUTCOME,
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        incomeVerificationType: POITYPE_DOC_UPLOAD,
        applicationStatus: REFERRED,
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
      }),
    },
    {
      label: 'Outcome - Conditionally Approved - POA',
      path: STEP_OUTCOME,
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        applicationStatus: CONDITIONALLY_APPROVED,
        applicationStatusReason: PROOF_OF_ADDRESS,
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
        creditLimitApproved: 3000,
      }),
    },
    {
      label: 'Outcome - Conditionally Approved - AML',
      path: STEP_OUTCOME,
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        applicationStatus: CONDITIONALLY_APPROVED,
        applicationStatusReason: ANTI_MONEY_LAUNDERING,
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
        creditLimitApproved: 3000,
      }),
    },
    {
      label: 'Outcome - Conditionally Approved - POI',
      path: STEP_OUTCOME,
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        applicationStatus: CONDITIONALLY_APPROVED,
        applicationStatusReason: PROOF_OF_INCOME,
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
        creditLimitApproved: 3000,
      }),
    },
    {
      label: 'Outcome - Conditionally Approved - POA & POI',
      path: STEP_OUTCOME,
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        applicationStatus: CONDITIONALLY_APPROVED,
        applicationStatusReason: PROOF_OF_ADDRESS_AND_PROOF_OF_INCOME,
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
        creditLimitApproved: 3000,
      }),
    },
    {
      label: 'Outcome - Conditionally Approved - AML & POI',
      path: STEP_OUTCOME,
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        applicationStatus: CONDITIONALLY_APPROVED,
        applicationStatusReason: ANTI_MONEY_LAUNDERING_AND_PROOF_OF_INCOME,
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
        creditLimitApproved: 3000,
      }),
    },
    {
      label: 'Outcome - Conditionally Approved - Upload Doc POA',
      path: STEP_OUTCOME,
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        incomeVerificationType: POITYPE_PAYSLIP_UPLOAD,
        applicationStatus: CONDITIONALLY_APPROVED,
        applicationStatusReason: PROOF_OF_ADDRESS,
        creditLimitApproved: 3000,
        applicationRef: '1234 5678 9012 3456',
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
      }),
    },
    {
      label: 'Outcome - Conditionally Approved - Upload Doc AML',
      path: STEP_OUTCOME,
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        incomeVerificationType: POITYPE_DOC_UPLOAD,
        applicationStatus: CONDITIONALLY_APPROVED,
        applicationStatusReason: ANTI_MONEY_LAUNDERING,
        creditLimitApproved: 3000,
        applicationRef: '1234 5678 9012 3456',
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
      }),
    },
    {
      label: 'Outcome - Conditionally Approved - Upload Payslips POI',
      path: STEP_OUTCOME,
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        incomeVerificationType: POITYPE_PAYSLIP_UPLOAD,
        applicationStatus: CONDITIONALLY_APPROVED,
        applicationStatusReason: PROOF_OF_INCOME,
        creditLimitApproved: 3000,
        applicationRef: '1234 5678 9012 3456',
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
      }),
    },
    {
      label: 'Outcome - Conditionally Approved - Upload Payslips AML & POI',
      path: STEP_OUTCOME,
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        incomeVerificationType: POITYPE_PAYSLIP_UPLOAD,
        applicationStatus: CONDITIONALLY_APPROVED,
        applicationStatusReason: ANTI_MONEY_LAUNDERING_AND_PROOF_OF_INCOME,
        creditLimitApproved: 3000,
        applicationRef: '1234 5678 9012 3456',
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
      }),
    },
    {
      label: 'Outcome - Conditionally Approved - Upload Payslips POA & POI',
      path: STEP_OUTCOME,
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        incomeVerificationType: POITYPE_PAYSLIP_UPLOAD,
        applicationStatus: CONDITIONALLY_APPROVED,
        applicationStatusReason: PROOF_OF_ADDRESS_AND_PROOF_OF_INCOME,
        creditLimitApproved: 3000,
        applicationRef: '1234 5678 9012 3456',
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
      }),
    },
    {
      label: 'Outcome - Conditionally Approved - Upload Documents POI',
      path: STEP_OUTCOME,
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        incomeVerificationType: POITYPE_DOC_UPLOAD,
        applicationStatus: CONDITIONALLY_APPROVED,
        applicationStatusReason: PROOF_OF_INCOME,
        creditLimitApproved: 3000,
        applicationRef: '1234 5678 9012 3456',
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
      }),
    },
    {
      label: 'Outcome - Conditionally Approved - Upload Documents AML & POI',
      path: STEP_OUTCOME,
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        incomeVerificationType: POITYPE_DOC_UPLOAD,
        applicationStatus: CONDITIONALLY_APPROVED,
        applicationStatusReason: ANTI_MONEY_LAUNDERING_AND_PROOF_OF_INCOME,
        creditLimitApproved: 3000,
        applicationRef: '1234 5678 9012 3456',
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
      }),
    },
    {
      label: 'Outcome - Conditionally Approved - Upload Documents POA & POI',
      path: STEP_OUTCOME,
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        incomeVerificationType: POITYPE_DOC_UPLOAD,
        applicationStatus: CONDITIONALLY_APPROVED,
        applicationStatusReason: PROOF_OF_ADDRESS_AND_PROOF_OF_INCOME,
        creditLimitApproved: 3000,
        applicationRef: '1234 5678 9012 3456',
        applicationErrors: null,
        applicationStartDate: new Date().toISOString(),
      }),
    },
    {
      label: 'Financial Table',
      path: `/outcome-fin-table?creditLimit=3,000&accountNumber=5218%209435%200015%20199&applicationDate=2024-07-31T05:54:58.945Z&productCode=${config.productCode}`,
      getState: () => ({
        acquisition: auAcquisitionComplete,
        applicationSubmitted: true,
        applicationStatus: APPROVED,
        applicationErrors: null,
        applicationDate: new Date().toISOString(),
        accountNumber: '5218 9435 0015 199',
        creditLimitApproved: 3000,
      }),
    },
  ],
  NZ: [
    // TODO: Add NZ routes
  ],
};

function buildState(state = {}) {
  const defaultState = getDefaultState();
  return defaultsDeep(state, defaultState);
}
